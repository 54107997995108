<template>
    <div class="about-terroir" :data-scroll-id="name">
        <div class="up-container">
            <div :class="{ dark: !lightMode }" class="text-content">
                <h2 :style="{ color: content.color }" class="title">
                    {{ content.title }}
                </h2>
                <div class="description" v-html="content.text"></div>
            </div>
            <div class="wine-list">
                <div
                    class="item"
                    v-for="item in wineLists"
                    :key="item.id"
                    :class="{ active: activeWineId === item.id }"
                >
                    <div :class="{ dark: !lightMode }" class="list-navbar">
                        <div
                            class="navbar-item"
                            v-for="item in wineLists"
                            :key="item.id"
                            @click="setActiveWine(item)"
                            :class="{ active: activeWineId === item.id }"
                        >
                            <div class="line"></div>
                            <div class="dot"></div>
                            <span class="title"
                                >{{ item.title }} - {{ item.category }}</span
                            >
                        </div>
                    </div>
                    <div class="image-container">
                        <img
                            :src="item.wines"
                            class="image"
                            alt=""
                            @click="linkPage"
                            :class="{ pointer: activeLink !== '' }"
                        />
                        <div class="content">
                            <span class="title">{{ item.teaser }}</span>
                            <!-- <header-title
                                text-color="white"
                                underline-color="white"
                                :name="item.title"
                            /> -->
                        </div>
                        <!-- <div class="gradient"></div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import HeaderTitle from "@/components/custom/HeaderTitle";
import first from "lodash/first";
import { mapGetters } from "vuex";
import { SUPPORTED_LANGUAGES } from "@/constants/trans";
import axios from "@/scripts/api.js";
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        },
        lightMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeWineId: 0,
            activeLink: "",
            category: []
        };
    },
    components: {
        // HeaderTitle
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice",
            lang: "language/getLang"
        }),
        activeLanguage() {
            return (
                SUPPORTED_LANGUAGES?.find(item => item.id === this.lang) || {}
            );
        },
        content() {
            let item = first(this.data?.list);
            // let tab = first(item?.content?.tabs);

            return {
                color: item.color,
                title: item.content_title,
                text: item.content
            };
        },
        wineLists() {
            let sort = first(this.data?.list)?.relations?.BrandWineCollections;
            let data = first(this.data?.list)?.relations_data
                ?.BrandWineCollections;
            let res = [];
            sort.forEach(item => {
                data.forEach(item2 => {
                    if (item === item2?.id) {
                        res.push({
                            title: item2?.title,
                            reaser: item2?.teaser,
                            id: item2?.id,
                            link: item2?.link,
                            category: this.category.find(
                                el => el.id === item2?.category[0]
                            )?.title,
                            wines: first(item2?.image)?.devices?.[
                                this.device?.type
                            ]
                        });
                    }
                });
            });
            return res;
            //     return first(
            //         this.data?.list
            //     )?.relations_data?.BrandWineCollections?.map(item => ({
            //         title: item.title,
            //         teaser: item.teaser,
            //         // image: first(item.image)?.devices?.[this.device?.type],
            //         id: item.id,
            //         link: item.link,
            //         category: this.category.find(el => el.id === item.category[0])
            //             ?.title,
            //         wines: first(item.image)?.devices?.[this.device?.type]
            //     }));
            // }
        }
    },
    methods: {
        async sendData() {
            const response = await axios("/indx", {});
            if (response.status === 200) {
                this.category = response.data?.terms?.category;
            }
            return true;
        },
        linkPage() {
            if (!this.activeLink) {
                return;
            }
            window.location = `/${this.activeLanguage.id}/${this.activeLink}`;
        },
        setActiveWine(item) {
            this.activeWineId = item.id;
            this.activeLink = item.link;
        }
    },
    created() {
        this.sendData();

        this.setActiveWine(first(this.wineLists));
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.about-terroir /deep/ {
    .up-container {
        .pointer {
            cursor: pointer;
        }
        padding: 144px 0 0;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            padding: 89px 0 0;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            padding: 55px 47px 0;
            flex-direction: column;
        }
        @media only screen and (max-width: 767px) {
            padding: 55px 16px 0;
            flex-direction: column;
        }

        .text-content {
            width: 50%;
            padding-left: 365px;
            text-align: left;
            padding-right: 150px;
            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                padding-left: 165px;
                padding-right: 90px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                padding-left: 128px;
                padding-right: 30px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                padding-left: 47px;
                padding-right: 30px;
            }
            @media only screen and (max-width: 1023px) {
                width: 100%;
                padding: 0;
            }

            &.dark {
                .description {
                    color: white;
                }
            }

            .title {
                color: #ea5053;
                font-size: 34px;
                font-weight: normal;
                @media only screen and (max-width: 767px) {
                    font-size: 21px;
                }
            }

            .description {
                color: #0a141c;
                margin-top: 34px;
                font-size: 14px;
                transition: all 0.4s $ease-out;
                @media only screen and (max-width: 767px) {
                    margin-top: 21px;
                }
            }
        }

        .wine-list {
            width: 50%;
            height: 536px;
            position: relative;
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                height: 545px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                height: 555px;
            }
            @media only screen and (max-width: 1023px) {
                width: 100%;
                margin-top: 56px;
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
                max-width: 100%;
                height: 520px;
                flex-direction: column-reverse;
            }

            .item {
                display: flex;
                position: absolute;
                flex-direction: row-reverse;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity 0.6s $ease-out;
                @media only screen and (max-width: 1023px) {
                    width: 100%;
                    justify-content: flex-end;
                }
                @media only screen and (max-width: 767px) {
                    display: block;
                }

                &.active {
                    opacity: 1;
                }
            }

            .image-container {
                width: 330px;
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: center;
                background: #c0c0c0;
                @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                    width: 285px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    width: 290px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    width: 50%;
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    height: 93%;
                    max-width: 100%;
                }

                .image {
                    height: 80%;
                    width: 100%;
                    // object-fit: cover;
                    object-fit: contain;
                    @media only screen and (max-width: 1024px) {
                        width: 100%;
                    }
                }

                .gradient {
                    position: absolute;
                    height: 50%;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background: linear-gradient(
                        #0a141c 0%,
                        rgba(10, 20, 28, 0) 100%
                    );
                    opacity: 0.7;
                    transform: rotate(180deg);
                }

                .content {
                    position: absolute;
                    bottom: 30px;
                    left: 30px;
                    text-align: left;
                    z-index: 12;

                    .title {
                        color: #e5b676;
                        font-weight: bold;
                        font-size: 14px;
                        // letter-spacing: 1.4px;
                    }

                    .header-title {
                        font-size: 34px;
                    }
                }
            }

            .list-navbar {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                @media only screen and (max-width: 767px) {
                    flex-direction: row;
                    overflow-x: auto;
                    margin-top: 0;
                    margin-bottom: 21px;
                }

                &.dark {
                    .navbar-item {
                        .title {
                            color: white;
                        }
                    }
                }

                .navbar-item {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-top: 12px;

                    &:first-child {
                        margin-top: 0;
                    }

                    &.active {
                        .title {
                            color: #e5b676 !important;
                        }

                        .line,
                        .dot {
                            opacity: 1;
                        }
                    }

                    @media only screen and (max-width: 767px) {
                        margin-top: 0;
                        margin-left: 12px;
                        &:first-child {
                            margin-left: 0;
                        }
                        .line,
                        .dot {
                            display: none;
                        }
                    }

                    .title {
                        color: #0a141c;
                        // letter-spacing: 1.4px;
                        transition: all 0.4s $ease-out;
                        text-align: left;
                        flex: 1;
                        padding-right: 20px;
                        @media (max-width: 1023px) {
                            padding-right: 0px;
                        }
                    }

                    .line {
                        width: 60px;
                        height: 1px;
                        background: #e5b676;
                        opacity: 0;
                        transition: opacity 0.4s $ease-out;
                    }

                    .dot {
                        @include circle(10);
                        background: #e5b676;
                        margin: 0 10px;
                        opacity: 0;
                        transition: opacity 0.4s $ease-out;
                    }
                }
            }
        }
    }
}
</style>
